
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import distribuidorConfig from "@/core/config/DistribuidorConfig";
import AuthService from "@/services/AuthService";
import { useRouter } from 'vue-router';

export default defineComponent({
  name: "error-500",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const userInfo = AuthService.getUsuarioAuth();
    const codNivel = userInfo.codNivel;

    function goToHome () {
      if (codNivel == 3){
        router.push({name: "erroConcessionaria"});
      }else{
        router.push({name: "login"});
      }
    }

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });

    return{
      goToHome
    }

  },
});


